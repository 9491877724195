<template>
  <div>
    <div class="mb-2">
      <quote-status-bar
        v-if="quote.id"
        :item="quote"
        :quote-status-bar="quoteStatusBar"
      />
    </div>
    <router-view />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'CreateView',
  components: {
    QuoteStatusBar,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, [])
    this.$store.commit(`${this.MODULE_NAME}/LIST_ATTACHMENTS`, [])
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, {
      products: [],
    })
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
      attachments: [],
    })
    this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {})
  },
  setup() {
    const { quoteStatusBar, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>
